@import url("https://fonts.googleapis.com/css?family=Permanent+Marker|Rock+Salt");
body{
  margin:0;
}
.App{
  font-family: sans-serif;
  text-align: center;
}
.player{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: rgba(98,175,246, .1);
}
.player.song {
  user-select: none;
  margin: 0 20px;
}
.song__title {
  text-align: left;
  margin: 0;
  color: white;
  font-family: "Permanent Marker", cursive;
  font-weight: normal;
  font-size: 3.5em;
}
.song__title:hover {
  color: greenyellow;
}
.song__artist{
  margin:0;
  color: white;
  font-family: "Rock Salt", cursive;
  font-weight: normal;
  font-size: 1em;
}
.song__artist:hover{
  color: greenyellow;
  cursor: pointer;
}
.controls{
  flex-grow: 1;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.player__button {
  width: fit-content;
  margin-bottom: 15px;
  background-color: transparent;
  border: none;
}
.player__button:focus {
  outline: none;
}
.player__button:hover {
  cursor: pointer;
}
.player__button svg {
  font-size: 4em;
  color: #62AFF6;
}
.bar {
  user-select: none;
  width: 100%;
  align-items: center;
  margin-top: -3px;
  padding:0 10px;
  position:relative;
  text-align: center
}
.bar .bar__time {
  font-size: 13px;
  margin-top:3px;
  font-family: "Hind Madurai";
  font-weight:600;
  color:#646F84;
  position: absolute
}
.bar__progress {
  flex: 1;
  border-radius: 5px;
  background:#646F84;
  height: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bar__progress__knob {
  position: relative;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #62AFF6;
}
.downloadBtn{
  border:0;
  width:14px;
}

@media screen and (max-width: 800px){
  .controls {
    width: 100%;
    margin-top: 20px;
  }
  .bar {
    width: 90%;
  }
}
@media screen and (max-width: 500px){
    .song .song__title {
      font-size: 2.5em;
    }
    .song__artist {
      font-size: 0.8em;
    }
}
